import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import Offer from "../modules/Offer";

const OfferTemplate = (props) => {
  const post = props.data.post;
  useEffect(() => {
    document.body.classList.remove("nav-menu-open");
  }, []);

  return (
    <Layout>
      <Seo seo={post.seo}></Seo>
      <Offer modules={post.offers} />
    </Layout>
  );
};

export default OfferTemplate;
export const pageQuery = graphql/* GraphQL */ `
  query OfferById($id: String!) {
    post: wpOffer(id: { eq: $id }) {
      title
      seo {
        canonical
        fullHead
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
      }
      ...OfferFragment
    }
  }
`;
