import React from "react";
import { graphql } from "gatsby";

import SinglePageBanner from "../components/SinglePageBanner";
import ImageWithTabContent from "../components/ImageWithTabContent";
import ImageWithContent from "../components/ImageWithContent";
import Gallery from "../components/Gallery";
import SimpleContent from "../components/SinglePage/SimpleContent";

const Offer = (props) => {
  const modules = props.modules.offersContent;

  const components = {
    SinglePageBanner,
    ImageWithTabContent,
    ImageWithContent,
    Gallery,
    SimpleContent
  }

  return (
    <>
      {modules
        ? modules.filter((module) => !module?.hideSection || module?.hideSection === "no").map((module, i) => {
            const moduleName = module.__typename.replace(
              "WpOffer_Offers_OffersContent_",
              ""
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                module,
                i,
              })
            );
          })
        : null}
    </>
  )
};

export default Offer;

export const OfferFragment = graphql`
  fragment OfferFragment on WpOffer {
    offers {
      offersContent {
        __typename
        ...OfferBannerFragment
        ...OfferSingleImageWithTabContentFragment
        ...OfferSingleImageWithContentFragment
        ...OfferSingleGalleryFragment
        ...OfferSingleSimpleContentFragment
      }
    }
  }
`;
