import React from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectFade, Navigation } from "swiper";

const ImageWithContent = ({ module }) => {
  return (
    <section className={`zigzag lg:py-60 py-30 lg:mt-60 mt-30${module.extraClass ? ` ${module.extraClass}` : ""}`}>
      <div className="container-fluid">
          <div className="flex flex-wrap items-center">
              <div className="lg:w-6/12 fade-img w-full pr-30 lgscreen:pr-0">
                <div className='zigzag-slider fade-img'>
                  <Swiper
                    spaceBetween={0}
                    loop={false}
                    speed={1000}
                    slidesPerView={1}
                    effect={"fade"}
                    navigation={true}
                    modules={[EffectFade, Navigation]}
                    className=""
                  >
                    {
                      module?.gallery?.length > 0 && module.gallery.map((slide, slideIndex) => (
                        <SwiperSlide key={slideIndex.toString()}>
                          <GatsbyImage 
                            image={getImage(slide?.image)}
                            alt={slide?.image?.altText}
                            className="img"
                          />
                          {
                            slide?.image?.caption && (
                              <span className="absolute bottom-45 left-40 text-white smscreen2:bottom-20 smscreen2:left-25">{parse(slide?.image?.caption)}</span>
                            )
                          }
                        </SwiperSlide>
                      ))
                    }
                  </Swiper>
                </div>  
              </div>

              <div className="lg:w-6/12 fade-ani w-full">
                  <div className="zigzag-content max-w-[443px] m-auto	">
                      {
                        module?.heading && (
                          <div className="title-black">
                            <h3>{module.heading}</h3>
                          </div>
                        )
                      }
                      <div className="content">
                        {module?.content && parse(module.content)}
                      </div>
                      <div className="btn-custom flex flex-wrap items-center space-x-8 mt-30">
                          {
                            module?.cta && (
                              module?.cta.target === "" ? (
                                <Link to={module.cta?.url} className="button btn-gold">
                                  {module?.cta?.title || "Link"}
                                </Link>
                              ) : (
                                <Link to={module.cta?.url} target="_blank" className="button btn-gold">
                                  {module?.cta?.title || "Link"}
                                </Link>
                              )
                              
                            )
                          }
                          {
                            module?.makeAnEnquiry && (
                              <Link to={module.makeAnEnquiry?.url} className="btn-link gold">
                                {module?.makeAnEnquiry?.title || "Link"}
                              </Link>
                            )
                          }
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
  )
}

export default ImageWithContent;
export const ImageWithContentFragment = graphql`
  fragment ImageWithContentFragment on WpAccommodation_Accommodations_PageContent_ImageWithContent {
    id
    content
    cta {
      target
      title
      url
    }
    extraClass
    heading
    imageStyle
    makeAnEnquiry {
      target
      title
      url
    }
    gallery {
      image {
        altText
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
        caption
      }
    }
  }
`;
export const OfferSingleImageWithContentFragment = graphql`
  fragment OfferSingleImageWithContentFragment on WpOffer_Offers_OffersContent_ImageWithContent {
    id
    hideSection
    content
    cta {
      target
      title
      url
    }
    extraClass
    heading
    imageStyle
    makeAnEnquiry {
      target
      title
      url
    }
    gallery {
      image {
        altText
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
        caption
      }
    }
  }
`;