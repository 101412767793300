import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const SinglePageBanner = ({ module }) => {
  return (
    <section className={`banner banner-style2 h-screen relative${module.extraClass ? ` ${module.extraClass}` : ""}`}>
      <div className="banner-img h-full bg-cover flex justify-start items-end">
        {
          module?.bannerImage && (
            <div className=""><GatsbyImage 
              image={getImage(module.bannerImage)}
              alt={module.bannerImage.altText}
              className="img"
            /></div>
          )
        }
        <div className="banner-info fade-ani w-[625px] px-15 text-left relative">
          {module?.preHeading && <span className="text-white text-14 font-heading uppercase">{module.preHeading}</span>}
          {module?.heading && <h1>{module.heading}</h1>}
          {
            module?.description && (
              <div className="text-white text-16 mt-20 lgscreen:mt-10">
                {parse(module.description)}
              </div>
            )
          }
          {
            module?.details?.length > 0 && (
              <ul className="text-white italic font-heading text-16 mt-25 flex flex-wrap banner-info-points">
                {
                  module.details.map((item, index) => (
                    <li key={index.toString()}>{item.point}</li>
                  ))
                }
              </ul>
            )
          }
        </div>
      </div>
    </section>
  )
}

export default SinglePageBanner;
export const SinglePageBannerFragment = graphql`
  fragment SinglePageBannerFragment on WpAccommodation_Accommodations_PageContent_SinglePageBanner {
    id
    hideSection
    description
    details {
      point
    }
    extraClass
    heading
    preHeading
    bannerImage {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1920)
      publicUrl
    }
  }
`;

export const OfferBannerFragment = graphql`
  fragment OfferBannerFragment on WpOffer_Offers_OffersContent_SinglePageBanner {
    id
    hideSection
    bannerImage {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1920)
      publicUrl
    }
    description
    extraClass
    heading
    preHeading
    details {
      point
    }
  }
`;
export const SinglePageExperienceSingleBannerFragment = graphql`
  fragment SinglePageExperienceSingleBannerFragment on WpExperience_Experiences_ExperiencesContent_SinglePageBanner {
    id
    hideSection
    description
    preHeading
    heading
    details {
      point
    }
    bannerImage {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1920)
      publicUrl
    }
  }
`;
export const SinglePageJourneySingleBannerFragment = graphql`
  fragment SinglePageJourneySingleBannerFragment on WpJourney_Journey_JourneyContent_SinglePageBanner {
    id
    hideSection
    description
    preHeading
    heading
    details {
      point
    }
    bannerImage {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1920)
      publicUrl
    }
  }
`;

export const BlogSingleSingleBannerFragment = graphql`
  fragment BlogSingleSingleBannerFragment on WpPost_Blogpost_PostContent_SinglePageBanner {
    id
    hideSection
    description
    preHeading
    heading
    details {
      point
    }
    bannerImage {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1920)
    }
  }
`;

