import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import parse from "html-react-parser";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ImageWithTabContent = ({ module }) => {
  const [tabOption, setTabOption] = useState(module?.tabs[0].heading || "");

  return (
    <section className={`zigzag lg:py-70 py-35 zigzag-tabs${module.extraClass ? ` ${module.extraClass}` : ""}`}>
      <div className="container-fluid">
        <div className="flex flex-wrap">
          <div className="lg:w-6/12 fade-img w-full pr-30 lgscreen:pr-0 lg:order-2">
            <GatsbyImage 
              image={getImage(module?.image)}
              alt={module?.image?.altText || ""}
            />
          </div>
          <div className="lg:w-6/12 fade-ani fade-ani w-full order-1">
            <div className="zigzag-content max-w-[443px] m-auto	">
              {
                module?.heading && (
                  <div className="title-black">
                    <h3>{module.heading}</h3>
                  </div>
                )
              }
              <ul className='tabs w-full space-x-8 mt-20 mb-24 flex'>
                {
                  module?.tabs?.length > 0 && module.tabs.map((tab, tabIndex) => (
                    <li key={tabIndex.toString()} onClick={() => { setTabOption(tab?.heading ? tab.heading : `Tab-${tabIndex}`) }} className={`tab-link cursor-pointer text-black-500 uppercase text-13 font-heading tracking-0.02 ${(tabOption == (tab?.heading || `Tab-${tabIndex}`)) ? 'tab-current' : ''}`}>{tab?.heading || `Tab-${tabIndex}`}</li>
                  ))
                }
              </ul>
              
              {
                module?.tabs?.length > 0 && module.tabs.map((tab, tabIndex) => tabOption === (tab?.heading || `Tab-${tabIndex}`) ? (
                  <div id="overview" className="tab-content global-list">
                    <div className="content">
                      {tab?.description && parse(tab.description)}
                    </div>
                  </div>
                ) : null)
              }
              {module?.price && <span className="text-black-500 italic font-heading text-14 mt-20 inline-block">{module?.price}</span>}
              <div className="btn-custom flex flex-wrap items-center space-x-8 mt-30">
                {
                  module?.bookNow && (
                    module?.bookNow.target === "" ? (
                      <Link to={module.bookNow?.url} className="button btn-gold">
                        {module?.bookNow?.title || "Book Now"}
                      </Link>
                    ) : (
                      <Link to={module.bookNow?.url} target="_blank" className="button btn-gold">
                        {module?.bookNow?.title || "Book Now"}
                      </Link>
                    )
                    
                    
                  )
                }
                {
                  module?.makeAnEnquiry && (
                    <Link to={module?.makeAnEnquiry?.url} className="btn-link gold">
                      {module?.makeAnEnquiry?.title}
                    </Link>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ImageWithTabContent;
export const ImageWithTabContentFragment = graphql`
  fragment ImageWithTabContentFragment on WpAccommodation_Accommodations_PageContent_ImageWithTabContent {
    id
    heading
    image {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
    }
    tabs {
      description
      heading
    }
    price
    bookNow {
      target
      title
      url
    }
    makeAnEnquiry {
      target
      title
      url
    }
  }
`;
export const OfferSingleImageWithTabContentFragment = graphql`
  fragment OfferSingleImageWithTabContentFragment on WpOffer_Offers_OffersContent_ImageWithTabContent {
    id
    hideSection
    heading
    image {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
    }
    tabs {
      description
      heading
    }
    price
    bookNow {
      target
      title
      url
    }
    makeAnEnquiry {
      target
      title
      url
    }
  }
`;

export const MainPageImageWithTabContentFragment = graphql`
  fragment MainPageImageWithTabContentFragment on WpPage_Pagecontent_PageContent_ImageWithTabContent {
    id
    bookNow {
      target
      title
      url
    }
    extraClass
    heading
    tabs {
      description
      heading
    }
    price
    makeAnEnquiry {
      target
      title
      url
    }
    image {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
    }
  }
`;