import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import parse from "html-react-parser";

const SimpleContent = ({ module }) => {
  return (
    <section className={`bg-with-content fade-ani ${module?.selectBackgroundStyle === "bg_white" ? "bg-white" : "bg-gray-200"} lg:py-80 py-60 lg:mt-60 mt-30${module.extraClass ? ` ${module.extraClass}` : ""}`}>
      <div className="md:w-8/12 w-full m-auto bg-content-list">
        <div className="top-info text-center max-w-[659px] m-auto">
          {
            module?.heading && (
              <div className="title-black">
                <h3>{module.heading}</h3>
              </div>
            )
          }
          <div className="content pt-30">
            {module?.description && parse(module.description)}
          </div>
          {
            module?.link && (
              <div className="btn-custom flex flex-wrap items-center justify-center space-x-8 mt-30">
                <Link to={module.link?.url} className="btn-link gold">
                  {module?.link?.title || "Link"}
                </Link>
              </div>
            )
          }
        </div>
        {
          module.secondaryDescription && (
            <div className="content-list global-list mt-40">
              <div className="grid grid-cols-4 mdscreen:grid-cols-2 lgscreen:gap-x-5 mdscreen:px-80 smscreen2:px-15">
                {parse(module.secondaryDescription)}
              </div>
            </div>
          )
        }
      </div>
    </section>
  )
}

export default SimpleContent;
export const AccommodationSimpleContentFragment = graphql`
  fragment AccommodationSimpleContentFragment on WpAccommodation_Accommodations_PageContent_SimpleContent {
    id
    description
    extraClass
    heading
    link {
      target
      title
      url
    }
    secondaryDescription
  }
`;
export const ExperienceSingleSimpleContentFragment = graphql`
  fragment ExperienceSingleSimpleContentFragment on WpExperience_Experiences_ExperiencesContent_SimpleContent {
    id
    description
    heading
    extraClass
    selectBackgroundStyle
    link {
      title
      target
      url
    }
  }
`;
export const OfferSingleSimpleContentFragment = graphql`
  fragment OfferSingleSimpleContentFragment on WpOffer_Offers_OffersContent_SimpleContent {
    id
    description
    heading
    extraClass
    selectBackgroundStyle
    link {
      title
      target
      url
    }
  }
`;
export const JourneySingleSimpleContentFragment = graphql`
  fragment JourneySingleSimpleContentFragment on WpJourney_Journey_JourneyContent_SimpleContent {
    id
    description
    selectBackgroundStyle
    extraClass
    heading
    link {
      title
      target
      url
    }
  }
`;