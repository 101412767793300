import React, { useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const Gallery = ({ module }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isLightBoxOpen, setLightBoxOpenStatus] = useState(false);

  return (
    <>
      <section className={`img-slider fade-img lg:py-60 py-30${module.extraClass ? ` ${module.extraClass}` : ""}`}>
        <Swiper
            spaceBetween={30}
            loop={true}
            slidesPerView={'auto'}
            speed={900}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
            >
              {
                module?.images?.length > 0 && module.images.map((image, index) => (
                  <SwiperSlide 
                    key={index.toString()} 
                    onClick={() => {
                      setPhotoIndex(index);
                      setLightBoxOpenStatus(true);
                    }}
                    className="cursor-pointer"
                  >
                    <GatsbyImage 
                      image={getImage(image)}
                      alt={image?.altText || "Slider Image"}
                      className="img"
                    />
                  </SwiperSlide>
                ))
              }
        </Swiper>
      </section>
      {isLightBoxOpen && (
        <Lightbox
          mainSrc={module?.images[photoIndex].mediaItemUrl}
          nextSrc={module?.images[(photoIndex + 1) % module?.images.length]}
          onImageLoadError={() => { }}
          imageLoadErrorMessage={() => { }}
          prevSrc={
            module?.images[
            (photoIndex + module?.images.length - 1) % module?.images.length
            ]
          }
          onCloseRequest={() => setLightBoxOpenStatus(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex) =>
                (photoIndex + module?.images.length - 1) % module?.images.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex(
              (photoIndex) => (photoIndex + 1) % module?.images.length
            )
          }
        />
      )}
    </>
  )
}

export default Gallery;
export const GalleryFragment = graphql`
  fragment GalleryFragment on WpAccommodation_Accommodations_PageContent_Gallery {
    id
    hideSection
    extraClass
    images {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 500)
      mediaItemUrl
    }
  }
`;
export const ExperienceSingleGalleryFragment = graphql`
  fragment ExperienceSingleGalleryFragment on WpExperience_Experiences_ExperiencesContent_Gallery {
    id
    hideSection
    extraClass
    images {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 500)
      mediaItemUrl
    }
  }
`;
export const OfferSingleGalleryFragment = graphql`
  fragment OfferSingleGalleryFragment on WpOffer_Offers_OffersContent_Gallery {
    id
    hideSection
    extraClass
    images {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 500)
      mediaItemUrl
    }
  }
`;